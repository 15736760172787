import SentryIgnoreErrors from "@/utils/SentryIgnoreErrors";

import("@sentry/browser").then((Sentry) => {
  const IGNORED_ERROR_CODES = [403, 404, 409, 422];

  const sentry_dsn = process.env.REACT_APP_SENTRY_DSN || "";
  if (sentry_dsn) {
    Sentry.init({
      dsn: sentry_dsn,
      environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
      ignoreErrors: SentryIgnoreErrors,
      beforeSend: (event, hint: Record<string, any>) => {
        // filter out axios errors that have a response status code
        if (
          hint?.originalException?.isAxiosError &&
          !!hint?.originalException?.response?.status &&
          IGNORED_ERROR_CODES.includes(
            hint?.originalException?.response?.status
          )
        ) {
          return null;
        }
        return event;
      },
    });
  }
});

import("amplitude-js").then(({ default: amplitude }) => {
  const amplitudeApiKey = process.env.REACT_APP_AMPLITUDE_API_KEY || "";
  if (amplitudeApiKey) {
    amplitude.getInstance().init(amplitudeApiKey);
  }
});

import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import "resize-observer-polyfill/dist/ResizeObserver.global";

import { instance as axios } from "@/hooks/api";
import { disableIosZoom } from "@/utils/mobile";
import { normalizeTourConfig } from "@/utils/tour-config";
import { getQueryParams } from "@/hooks/queryParams";

import FullScreenLoader from "@/components/FullScreenLoader";
import NotFound from "./NotFound";
import { View } from "react-native";
import { ThemeProvider } from "@smartrent/ui";
import { yupPhone, yupDate } from "@smartrent/utils";
import * as Yup from "yup";

Yup.addMethod(Yup.string, "phone", yupPhone);
Yup.addMethod(Yup.string, "date", yupDate);

const App = lazy(() => import("./App"));

function getParams() {
  const tourMatch = window.location.pathname.match(/\/tour\/([a-zA-Z0-9\-]*)?/);
  const tourToken = tourMatch && tourMatch[1];

  const queryParams: { unitIds?: string; source?: string } = getQueryParams(
    window.location.search
  );
  const units: Number[] | null = queryParams.unitIds
    ? queryParams.unitIds.split(",").map((unit) => Number(unit))
    : null;

  const propertyMatch = window.location.pathname.match(
    /\/property\/([a-zA-Z0-9\-]*)?/
  );
  const groupTourConfigUuid = propertyMatch && propertyMatch[1];

  const source = decodeURIComponent(queryParams.source ?? "");

  return { tourToken, groupTourConfigUuid, units, source };
}

async function fetchPropertyDataFromTourConfigUuid(
  groupTourConfigUuid: string
) {
  if (!groupTourConfigUuid) {
    return null;
  }
  return await axios
    .get(`/config/${groupTourConfigUuid}`)
    .then((response) => response.data)
    .then(normalizeTourConfig)
    .catch((_e: any) => {
      return null;
    });
}

async function fetchPropertyDataFromTourToken(tourToken: string) {
  return await axios
    .get(`/tour/${tourToken}/config`)
    .then((response) => response.data)
    .then(normalizeTourConfig)
    .catch((_e: any) => {
      return null;
    });
}

disableIosZoom();
// disable pinch zooming as well as double tap to zoom on ios

(async () => {
  const { groupTourConfigUuid, tourToken, source } = getParams();

  if (source) {
    localStorage.setItem("source", JSON.stringify(source));
  }

  let error = false;

  let props = {};
  if (groupTourConfigUuid) {
    const config = await fetchPropertyDataFromTourConfigUuid(
      groupTourConfigUuid
    );
    if (!config) {
      error = true;
    }
    props = { config };
  } else if (tourToken) {
    const config = await fetchPropertyDataFromTourToken(tourToken);
    if (!config) {
      error = true;
    }
    props = { config };
  }

  ReactDOM.render(
    <Suspense
      fallback={
        <ThemeProvider initialMode="light">
          <FullScreenLoader />
        </ThemeProvider>
      }
    >
      {error ? (
        <ThemeProvider initialMode="light">
          <View
            style={{
              alignItems: "center",
              height: "100%",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <NotFound />
          </View>
        </ThemeProvider>
      ) : (
        <App {...props} />
      )}
    </Suspense>,
    document.getElementById("root")
  );
})();
